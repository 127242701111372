import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  DEFAULT_EMPTY,
  DELAY_TRANSITION,
  TIME_FORMAT,
  UNIT_PRICE,
} from 'constant';
import { isNull, isUndefined } from 'lodash';
import moment from 'moment';
import i18n from './i18n';
import { LocalStorage, STORAGE_KEY } from './localStorage';
import { LANGUAGE, PAYMENT_PLAN_TYPE } from 'enum';
import { ClubType } from 'types';

const Storage = new LocalStorage();

export const showData = (data: any) => {
  if (isNull(data) || isUndefined(data) || data.length === 0) return DEFAULT_EMPTY;
  else return data;
};

export const formatPrice = (price: number, isUnit?: boolean) => {
  return (
    price
      .toLocaleString('it-IT', { style: 'currency', currency: 'vnd' })
      .replace(/[^0-9\.-]+/g, '') + (isUnit ? `${UNIT_PRICE}` : '')
  );
};

export const isSuccessCode = (code?: number) => {
  return code ? String(code).length === 4 : false;
};

export const delayNavigate = (callback: () => any) => {
  setTimeout(() => {
    callback();
  }, DELAY_TRANSITION);
};

export const formatDate = (date?: string) => {
  return date ? moment(date).format(DATE_FORMAT) : '';
};

export const formatTime = (date?: string) => {
  return date ? moment(date).format(TIME_FORMAT) : '';
};

export const formatDateTime = (date?: string) => {
  return date ? moment(date).format(DATETIME_FORMAT) : '';
};

export const formatDateTimeNow = (date?: string) => {
  const now = moment();
  const inputDate = moment(date);
  if (now.get('day') - inputDate.get('day') === 0) {
    if (isVN()) inputDate.locale('vi');
    else {
      inputDate.locale('en-us');
    }

    return date ? inputDate.fromNow() : '';
  } else return date ? moment(date).format(DATETIME_FORMAT) : '';
};

export const isNowDay = (date?: string) => {
  const now = moment();
  const inputDate = moment(date);

  return now.get('day') - inputDate.get('day') === 0;
};

export const getVersion = () => Storage.getStorageItem(STORAGE_KEY.APP_VERSION);

export const isPWA = () =>
  process.env.REACT_APP_DEV_MODE === 'true'
    ? true
    : window.matchMedia('(display-mode: standalone)').matches;

export const isVN = () => {
  return i18n.language === LANGUAGE.VN;
};

export const resetScroll = () => {
  const body = document.body;
  if (body) {
    body.scrollTop = 0;
  }
};

export const resetScrollElement = (id: string) => {
  const body = document.getElementById(id);
  if (body) {
    body.scrollTop = 0;
  }
};

export const scrollToId = (id: string, offset = 0) => {
  const element = document.getElementById(id);

  if (element) {
    document.body?.scrollTo({
      top: element.offsetTop - offset,
      behavior: 'smooth',
    });
  }
};

export const getImageUrl = (url: string) => {
  return `${process.env.REACT_APP_IMAGE_URL}?id=${url}`;
};

export const getDistanceFromLatLonInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km

  return d;
};

export const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

export const getLocation = (
  onInit: () => void,
  onSuccess: (position: GeolocationPosition) => void,
  onFail: (positionError: GeolocationPositionError) => void,
  onTimeOut: () => void,
) => {
  if (navigator.geolocation) {
    onInit && onInit();
    navigator.geolocation.getCurrentPosition(onSuccess, onFail);
    onTimeOut &&
      setTimeout(() => {
        onTimeOut();
      }, 1000);
  }
};

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',');
  const mime = (arr[0].match(/:(.*?);/) || [])[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const getMimeTypeFromBase64 = (base64: string) => {
  // RegExp to extract the MIME type part of the Base64 string
  const result = base64.match(/^data:(.*?);base64,/);

  if (result && result.length > 1) {
    return result[1];
  } else {
    return null; // or assume a default type, or throw an error, etc.
  }
};

export const base64ToBlob = (base64: string) => {
  const byteCharacters = atob(base64.split(',')[1]);
  const mineType = getMimeTypeFromBase64(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mineType || 'image/jpeg' });
};

export const blobToDataUrl = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(String(reader.result));
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const joinAddress = (club: ClubType) => {
  return [club.address, club.district?.districtName, club.city?.cityName].join(', ');
};

export const formatVPPayReturnCard = (cardMask: string) => {
  return cardMask.slice(cardMask.length - 5).replaceAll('x', '*');
};

export const isSubscriptionPlan = (type: PAYMENT_PLAN_TYPE) => {
  return (
    type === PAYMENT_PLAN_TYPE.SUBSCRIPTION || type === PAYMENT_PLAN_TYPE.BLACK_CARD_SUBSCRIPTION
  );
};
