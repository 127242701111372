/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './styles.module.scss';
import { Link, useParams } from 'react-router-dom';
import PriorityMembershipPlan from 'components/card/PriorityMembershipPlan';
import { ComparePlan, Faqs, PublicPromotionCard } from 'components';
import TabWrapper from 'components/shared/TabWrapper';
import { Element } from 'react-scroll';
import { useDirection } from 'hooks';
import GeneralService from 'services/general.service';
import {
  delayNavigate,
  isSubscriptionPlan,
  isSuccessCode,
  isVN,
  scrollToId,
  showData,
} from 'utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ClubType, DetailCouponType } from 'types';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd-mobile';
import { ROUTES } from 'constant';
import GlobalService from 'services/global.service';
import { PaymentPlanType } from 'types/global.type';
import {
  BASIC_INFO_MAPPING,
  MORE_INFO_MAPPING,
  PLAN_NAME_MAPPING,
  SLOGAN_MAPPING,
  SORT_PLAN_PRIORITY,
} from 'constant/plan';
import { PAYMENT_PLAN_TYPE } from 'enum';
import ComparePlanDesktop from 'components/shared/ComparePlanDesktop';
import { keyBy, sortBy } from 'lodash';
import { isMobile } from 'react-device-detect';

type TabType = {
  title: string;
  key: string;
  id: number;
}[];

export default function SelectMembershipContainer() {
  const { t } = useTranslation();
  const { clubId } = useParams();
  const { goTo } = useDirection();
  const generalService = new GeneralService();
  const globalService = new GlobalService();
  const [clubDetail, setClubDetail] = useState<ClubType>();
  const [plans, setPlans] = useState<PaymentPlanType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlan, setIsLoadingPlan] = useState(false);
  const [activeTab, setActiveTab] = useState<string | number>();
  const planSaved = useRef<PaymentPlanType[]>([]);

  const getClubDetail = async () => {
    setIsLoading(true);
    const response = await generalService.getClubDetail({
      clubId,
    });
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setClubDetail(data);
    }
    delayNavigate(() => setIsLoading(false));
  };

  const getPlansInClubs = async () => {
    setIsLoadingPlan(true);
    const response = await globalService.getPaymentPlans({
      clubId: String(clubId),
    });
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setPlans(data);
      planSaved.current = data;
    }
    delayNavigate(() => setIsLoadingPlan(false));
  };

  const listPromotion = useMemo(() => {
    const result: DetailCouponType[] = [];
    plans.forEach((plan) => {
      if (plan?.paymentPlanPromotions) {
        const promotion = plan.paymentPlanPromotions?.find(
          (promotion) => !promotion.coupon?.isPrivate,
        );
        promotion && result.push(promotion);
      }
    });

    return result;
  }, [plans]);

  const handleChangeActiveTab = (newTab: number | string) => {
    setActiveTab(newTab);
    scrollToId(String(newTab), 130);
  };

  const handleSelectPlan = (membershipId: number | string) => () => {
    goTo(
      ROUTES.SEND_OTP.replace(':clubId', String(clubId)).replace(
        ':membershipId',
        String(membershipId),
      ),
    )();
  };

  const promotionDiscount = (plan: PaymentPlanType) => {
    const promotions = plan.paymentPlanPromotions;
    if (!promotions) return null;
    const promotionInfo = promotions.find((promotion) => !promotion.coupon?.isPrivate);

    return {
      code: promotionInfo?.coupon?.code,
      discount: Number(promotionInfo?.discountJoiningFee),
      membershipDiscount: Number(promotionInfo?.discountMemberShip),
    };
  };

  const sortedPlan = useMemo(() => {
    const planMap = plans.map((plan) => ({ ...plan, priority: SORT_PLAN_PRIORITY[plan.type] }));

    return sortBy(planMap, 'priority');
  }, [plans]);

  const tabList = useMemo(() => {
    const result: TabType = [];
    const normalPlan = sortedPlan.filter((plan) => !isSubscriptionPlan(plan.type));
    const subPlan = sortedPlan
      .filter((plan) => isSubscriptionPlan(plan.type))
      .sort((a, b) => (a.type === PAYMENT_PLAN_TYPE.BLACK_CARD_SUBSCRIPTION ? -1 : 1));
    if (subPlan.length > 0) {
      subPlan.forEach((plan) => {
        if (plan.type === PAYMENT_PLAN_TYPE.SUBSCRIPTION)
          result.push({
            title: 'BASIC SUB',
            key: `SUBSCRIPTION_${plan.id}`,
            id: Number(plan?.id),
          });
        if (plan.type === PAYMENT_PLAN_TYPE.BLACK_CARD_SUBSCRIPTION)
          result.push({
            title: 'BLACK CARD SUB',
            key: `SUBSCRIPTION_${plan.id}`,
            id: Number(plan?.id),
          });
      });
    }
    normalPlan
      .sort((a, b) => (Number(a.duration?.value) > Number(b?.duration?.value) ? 1 : -1))
      .forEach((plan) => {
        result.push({
          title: `${plan.duration?.value} ${
            Number(plan?.duration?.value) > 1 ? t('title.months') : t('title.month')
          }`,
          key: `${plan.type}_${plan.id}`,
          id: Number(plan?.id),
        });
      });
    setActiveTab(isMobile ? result[0]?.key : undefined);

    return result;
  }, [sortedPlan]);

  const handleCopyPromotionCode = (planId: number) => () => {
    const scrollKey = tabList.find((tab) => tab.id === planId);
    setTimeout(() => {
      if (scrollKey) handleChangeActiveTab(scrollKey?.key);
    }, 500);
  };

  const renderPlanList = (planList: PaymentPlanType[]) => {
    return planList.map((plan) => {
      const numberOfPlans = sortedPlan.length;
      const tabListKey = keyBy(tabList, 'id');

      return (
        <div
          id={tabListKey[Number(plan.id)]?.key}
          key={plan.id}
          className={styles.item}
          style={{
            minWidth: '370px',
            width: `calc(100% / ${numberOfPlans} - (16px * ${
              numberOfPlans - 1
            }) / ${numberOfPlans})`,
            maxWidth: '450px',
          }}
        >
          <PriorityMembershipPlan
            isDefault={plan?.isDefault}
            isHighLight={isMobile ? false : tabListKey[Number(plan.id)]?.key === activeTab}
            mainInfo={{
              description: SLOGAN_MAPPING[plan.type]?.description,
              duration: Number(plan.duration?.value),
              planName: PLAN_NAME_MAPPING[plan.type],
              pricePerMonth: Number(plan?.membershipPricePerMonth),
              price: plan.membershipFee || 0,
              slogan: SLOGAN_MAPPING[plan.type]?.slogan,
              unit: isSubscriptionPlan(plan.type)
                ? Number(promotionDiscount(plan)?.membershipDiscount || 0) > 0 ||
                  Number(plan?.firstBuySubscriptionDiscount || 0) > 0
                  ? 'title.first-month'
                  : 'title.monthly'
                : 'title.upfront',
              tagName: isSubscriptionPlan(plan.type)
                ? 'SUBSCRIPTION'
                : `${plan.duration?.value} ${
                    Number(plan?.duration?.value) > 1 ? t('title.months') : t('title.month')
                  }`,
              originalPrice: Number(plan.membershipFee),
            }}
            promotionCode={
              plan?.type === PAYMENT_PLAN_TYPE.BASIC ? promotionDiscount(plan)?.code : undefined
            }
            totalPaymentFirst={
              Number(plan.totalPrice) - Number(promotionDiscount(plan)?.discount || 0)
            }
            nextPayment={plan.membershipPricePerMonth}
            joiningFeeDiscount={
              isSubscriptionPlan(plan.type)
                ? Number(plan.joiningFee || 0) + Number(promotionDiscount(plan)?.discount || 0)
                : promotionDiscount(plan)?.discount
            }
            membershipDiscount={
              isSubscriptionPlan(plan.type)
                ? Number(plan?.firstBuySubscriptionDiscount || 0) +
                  Number(promotionDiscount(plan)?.membershipDiscount || 0)
                : Number(promotionDiscount(plan)?.membershipDiscount || 0)
            }
            joiningFee={plan.joiningFee}
            basicInfos={BASIC_INFO_MAPPING[plan.type]}
            moreInfos={MORE_INFO_MAPPING[plan.type]}
            onSelect={handleSelectPlan(String(plan.id))}
          />
        </div>
      );
    });
  };

  useEffect(() => {
    getClubDetail();
    getPlansInClubs();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.clubWrapper}>
        {isLoading ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-lg)',
            }}
          />
        ) : (
          <>
            <p className="font-lg color-black font-bold">
              {`TNG ${showData(isVN() ? clubDetail?.nameVi : clubDetail?.nameEn)}`}
            </p>
            <Link className="font-md color-blue font-light" to={ROUTES.PAYMENT_HOME}>
              {t('button.change')}
            </Link>
          </>
        )}
      </div>
      <div className={styles.info}>
        <p className="font-xxl color-black font-bold">{t('title.select-a-membership')}</p>
        <p
          className="font-sm color-black font-light"
          style={{
            marginTop: '8px',
            marginBottom: '11px',
          }}
        >
          {t('title.select-right-membership')}
        </p>
        {listPromotion.length > 0 && (
          <div className={styles.promotionWrapper}>
            {listPromotion.map((promotion) => {
              return (
                <PublicPromotionCard
                  key={promotion?.id}
                  title={
                    isVN()
                      ? promotion?.coupon?.campaign?.nameVi
                      : promotion?.coupon?.campaign?.nameEn
                  }
                  description={
                    isVN()
                      ? promotion?.coupon?.campaign?.descriptionVi
                      : promotion?.coupon?.campaign?.descriptionEn
                  }
                  code={promotion?.coupon?.code}
                  onCopyAction={handleCopyPromotionCode(Number(promotion.paymentPlanId))}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className={styles.tabs}>
        <TabWrapper
          activeKey={activeTab}
          items={Object.values(tabList).map((tab) => {
            return {
              key: tab.key,
              title: tab.title,
            };
          })}
          onChangeTab={handleChangeActiveTab}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          marginBottom: '32px',
        }}
      >
        <div className={styles.plan}>
          {isLoadingPlan ? (
            <>
              <div className={styles.item}>
                <PriorityMembershipPlan
                  isDefault={true}
                  isSkeleton
                  basicInfos={[]}
                  moreInfos={[]}
                />
              </div>
              <div className={styles.item}>
                <PriorityMembershipPlan
                  isDefault={false}
                  isSkeleton
                  basicInfos={[]}
                  moreInfos={[]}
                />
              </div>
              <div className={styles.item}>
                <PriorityMembershipPlan
                  isDefault={false}
                  isSkeleton
                  basicInfos={[]}
                  moreInfos={[]}
                />
              </div>
            </>
          ) : (
            renderPlanList(sortedPlan.filter((plan) => isSubscriptionPlan(plan.type)))
          )}
        </div>
        <div className={styles.plan}>
          {renderPlanList(sortedPlan.filter((plan) => !isSubscriptionPlan(plan.type)))}
        </div>
      </div>
      <div>
        <Element name="firstInsideContainer">
          <div className={styles.comparePlan}>
            <ComparePlan />
          </div>
          <div className={styles.comparePlanDesktop}>
            <ComparePlanDesktop />
          </div>
        </Element>
      </div>

      <div className={styles.faqs}>
        <Faqs />
      </div>
    </div>
  );
}
