import { APIResponse, GetCouponDetailReq, GetListPromotionReq } from 'types';
import { AxiosGW } from './axios.service';

export default class PromotionService {
  async getListCoupons(payload: GetListPromotionReq) {
    const { paymentPlanId } = payload;

    return (await AxiosGW.get(`/api/v1/promotion/payment-plan/${paymentPlanId}/coupons`))
      .data as APIResponse;
  }

  async getCouponDetail(payload: GetCouponDetailReq) {
    const { paymentPlanId, couponId, clubId } = payload;

    return (
      await AxiosGW.get(
        `/api/v1/promotion/club/${clubId}/payment-plan/${paymentPlanId}/coupon/${couponId}/apply`,
      )
    ).data as APIResponse;
  }

  async getManualCouponDetail(payload: GetCouponDetailReq) {
    const { paymentPlanId, couponCode, clubId } = payload;

    return (
      await AxiosGW.get(`/api/v1/promotion/club/${clubId}/payment-plan/${paymentPlanId}/apply`, {
        params: {
          couponCode,
        },
      })
    ).data as APIResponse;
  }
}
